/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap'); */

* {
  box-sizing: border-box;

  /* background-color: #00B74A; */


  font-family: 'Helvetica', sans-serif;

}

body {
  background-color: #fff;
}

.container {
  overflow: auto;
  min-height: 300px;
  margin-top: 5px;
  margin-right: 30px;
  margin-left: 30px;

}

.btn {
  display: inline-block;
  background: #000;
  color: #fff;
  border: none;
  /* padding: 10px 10px; */
  margin: 5px 0 5px 0;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  font-size: 3vw;
  /* font-family: inherit; */
}


.add-new-recipe-btn {

  font-size: 625px;
  height: 75px;
  width: 45vw;
  max-width: 450px;
  color: #FFFEE0;
}

.title {
  font-size: 80px;
  color: #FFFEE0;
}



/* for the green box */
.header-box {

  height: 120px;
  margin-left: -30px;
  margin-right: -30px;
  background-color: green;

}


.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 120px;
  margin-bottom: 20px;
  margin-left: 30px;
  margin-right: 30px;

}

/* recipe start */

.recipe {
  border: 4px solid green;
  border-radius: 5px;
  padding: 10px 10px;
  cursor: pointer;
  margin-top: 5px;
  margin-bottom: 5px;
}


.recipes-table {
  /* display: flex;
  justify-content: center;
  align-items: center; */
  table-layout: fixed;
  width: 100%;
  font-size: 3.5vw;

}

td {

  text-align: center;
}

#recipe-name {
  font-weight: bold;
}

.full-recipe {
  text-align: center;
}

.full-recipe-headings {
  margin-bottom: 25px;
  margin-top: 50px;
  text-decoration: underline;
  text-decoration-color: green;
  font-size: 5vw;

}

#ingredients {
  font-size: 5vw;
}

#preparation {
  font-size: 4vw;
  white-space: pre-wrap;
}

.full-recipe-paragraphs {
  font-weight: bold;
  white-space: pre-wrap;
  margin-bottom: 50px;
}




hr {
  border: 3px solid;
  border-color: rgb(0, 128, 0);
  border-radius: 3px;
  opacity: 0.6;
}

#upper-hr {
  margin-top: 10px;

}

.recipe-error-message {
  text-align: center;
  margin: 10px 0 10px 0;
  color: red;
  font-size: 20px;
}

.recipe-btn {
  width: 100%;
  height: 8vw;
  max-height: 50px;
}

.update-recipe-btn {
  background-color: blue;
  margin-top: 10px;
}

.add-recipe-btn {
  background-color: #86C679;
  margin-top: 10px;
}

/* #delte-and-edit-buttons {
  background-color: green;
} */

/* recipe end */

/* add recipe start */

.add-form {
  border-radius: 5px;
  padding: 10px 10px 10px 10px;

}

.add-form label {
  font-size: 20px;
  color: green;
}

.form-control {
  margin-top: 10px;
  /* margin-right: 10px; */
}

.form-control label {
  display: block;
}

.form-control input {
  width: 100%;
  height: 40px;
  margin-top: 5px;
  padding: 3px 7px;
  font-size: 17px;
}

.form-control textarea {
  width: 100%;
  height: auto;
  margin-top: 5px;
  padding: 3px 7px;
  font-size: 17px;
  resize: none;
  white-space: pre-wrap;
  /* overflow: hidden; */
}


/* .form-control-check {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.form-control-check label {
  flex: 1;
}

.form-control-check input {
  flex: 2;
  height: 20px;
} */


/* add recipe end */




.btn:focus {
  outline: none;
}

.btn:active {
  transform: scale(0.98);
}

.btn-block {
  display: block;
  width: 100%;

}

.login-form {
  margin-right: 30px;
  margin-left: 30px;
}

.login-form-btn {
  text-align: center;
  height: 8vw;
  max-height: 50px;
}

.main-btn {
  background-color: #5783EA;
}

.secondary-btn {
  background-color: #A8EEEA;
  margin-top: 10px;
  margin-bottom: 10px;

}




#login-heading {
  color: #5783EA;
}

.login-btn {
  /* margin-right: 50px; */
  height: 30px;
  font-size: 12px;
  width: 100px;

  /* padding-bottom: 25px; padding is used to keep text center - doesnt work when logged in */
}

/* LOGIN HEADER start */
#login-div {
  display: flex;
  justify-content: right;
  align-items: center;

}

#logout-div {
  display: flex;

  justify-content: right;
  align-items: center;
  height: 40px;
}

#user-greeting {
  margin-top: 20px;
  margin-right: 30px;
  text-decoration: none;

}

/* LOGIN HEADER end */

/* FOOTER start */
/* 
 Footer {

  background-color: white;
} */

#footer-link {
  font-size: 10px;
  text-decoration: none;
}


footer {
  margin-top: 30px;
  text-align: center;
}

/* FOOTER end */


/* SCREEN SIZES start */

/* iphone se */
@media screen and (max-width: 375px) {

  .title {
    font-size: 30px;
  }

  .add-new-recipe-btn {
    font-size: 15px;
  }

  .header {
    margin-right: 10px;
    margin-left: 10px;
  }

  .container {
    margin-right: 0;
    margin-left: 0;
  }

  .header-box {
    margin-right: -5px;
    margin-left: -5px;
  }

  /* .login-btn {
    margin-right: 5px;
  } */

  #login-header {
    margin-right: 5px;
  }

}



/* iphone xr */
@media screen and (min-width: 375px) {

  .title {
    font-size: 35px;
  }

  .add-new-recipe-btn {
    font-size: 20px;
  }

  .header {
    margin-right: 10px;
    margin-left: 10px;
  }

  .container {
    margin-right: 0;
    margin-left: 0;
  }

  .header-box {
    margin-right: -5px;
    margin-left: -5px;
  }

  /* .login-btn {
    margin-right: 5px;
  } */

  #login-header {
    margin-right: 5px;
  }


}


/* all phones */
/* login header left and right instead of just left */
@media screen and (max-width: 600px) {

  #login-div {
    display: flex;
    justify-content: right;
    align-items: center;

  }

  #logout-div {
    display: flex;

    justify-content: space-between;
    align-items: center;
    height: 40px;
  }

  #user-greeting {
    margin-top: 20px;
    margin-left: 10px;
    text-decoration: none;
    margin-right: 0;
  }

}

@media screen and (min-width: 601px) {

  .title {
    font-size: 50px;
  }

  .recipe-btn {
    font-size: 15px;
  }

  .login-form-btn {
    font-size: 15px;
  }

  .add-new-recipe-btn {
    font-size: 25px;
  }

}

/* limit recipes table and full recipe font size */
@media screen and (min-width: 900px) {

  #ingredients {
    font-size: 50px;
  }

  #preparation {
    font-size: 40px;
  }

  .full-recipe-headings {
    font-size: 50px;

  }

  .recipes-table {
    font-size: 30px;
  }

  .add-new-recipe-btn {
    font-size: 2.5vw;
  }


}



@media screen and (min-width: 1000px) {
  .container {
    margin: 5px 5vw 0 5vw;
  }

  .header {
    margin-left: 5vw;
    margin-right: 5vw;
  }

  .login-form {
    margin-left: 5vw;
    margin-right: 5vw;
  }


  #login-header {
    margin-right: 30px;
  }

}

@media screen and (min-width: 1300px) {
  .container {
    margin: 5px 10vw 0 10vw;
  }

  .header {
    margin-left: 10vw;
    margin-right: 10vw;
  }

  .login-form {
    margin-left: 10vw;
    margin-right: 10vw;
  }

  .add-new-recipe-btn {
    font-size: 30px;
  }

}

@media screen and (min-width: 1600px) {
  .container {
    margin: 5px 15vw 0 15vw;


  }

  /* .header {
    margin: 0 15vw 0 15vw;
  } */

  .header {
    /* background-color: red; */
    margin-left: 15vw;
    margin-right: 15vw;
  }

  .login-form {
    margin-left: 15vw;
    margin-right: 15vw;
  }


}

/* SCREEN SIZES end */

/* ios fix for round buttons */
input,
textarea {
  -webkit-appearance: none;
  border-radius: 0;
}

#top-warning {
  color: white;
  text-align: center;
  background-color: red;
}



/* LODING SPINNER start */

.loading-spinner-div {
  display: flex;
  justify-content: center;

  text-align: center;
}

.main-page-loading-spinner {
  margin-top: 100px;
}

.full-recipe-loading-spinner {
  margin-top: 50px;
}

/* LODING SPINNER end */


/* ABOUT start */

.about-div {
  text-align: center;
}

/* ABOUT end */